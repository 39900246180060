import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, useState } from 'react'

import { Carousel } from '@/features/carousel'
import { mq, widthInCols } from '@/theme/mixins'

import { MultimediaBlockImage } from './MultimediaBlockImage'
import { MultimediaBlockVideo } from './MultimediaBlockVideo'

export type MultimediaBlockLayout = 'ARTICLE'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.MultimediaBlockFragment | null
  layout: MultimediaBlockLayout
}

export const MultimediaBlock = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const [height, setHeight] = useState<number | null>(null)
  const styles = {
    slider: css`
      ${layout === 'ARTICLE' &&
      css`
        /* grid-column: 1 / -1; */
        --border-thickness: 0px;
        --bg-color: #fff;
        --gtr: calc(2 * var(--border-thickness) + var(--gtr-36));
        --slide-width: min(
          var(--max-width),
          ${widthInCols({ count: 11 })}
        );
        --scroll-width: calc(var(--slide-width) + var(--gtr));
        width: var(--grid-w);
        margin: 1em 0 2.5em;
        height: calc(${height}px + 2rem);
        transition: height 750ms ease;
        ${mq().s} {
          --border-thickness: 0.75rem;
          --slide-width: calc(var(--grid-w) - 3 * var(--margin));
        }
      `}
    `,
    sliderContent: css`
      display: grid;
      grid-column-gap: var(--gtr);
      grid-template-columns: repeat(
        ${data?.media?.length},
        var(--slide-width, auto)
      );
      ${layout === 'ARTICLE' &&
      css`
        padding: 0
          calc(var(--grid-w) - var(--slide-width) - var(--margin)) 0
          var(--margin);
        ${mq().s} {
          padding: 0 calc(1.5 * var(--margin));
        }
      `}
    `,
    scrollArea: css`
      ${layout === 'ARTICLE' &&
      css`
        ${mq().s} {
          scroll-padding: 0 calc(1.5 * var(--margin));
        }
      `}
    `,
    nav: css`
      ${layout === 'ARTICLE' &&
      css`
        width: calc(var(--slide-width) + 2 * var(--margin));
        ${mq().s} {
          width: 100%;
        }
      `}
    `,
    videoContainer: css`
      background: rgba(0, 0, 0, 0.9);
      display: grid;
      position: relative;
      align-self: flex-start;
    `,
  }
  return (
    <Carousel
      css={styles.slider}
      contentCss={styles.sliderContent}
      navCss={styles.nav}
      scrollAreaCss={styles.scrollArea}
      snap
      navVariant={layout === 'ARTICLE' ? 'OVERLAY' : 'CORNER'}
      {...props}
    >
      {data?.media?.map((block, i) => {
        switch (block?.__typename) {
          case 'DatoCmsImageBlock':
            return (
              <MultimediaBlockImage
                data={block}
                carouselHeight={height}
                setCarouselHeight={setHeight}
                layout={layout}
                key={i}
              />
            )
          case 'DatoCmsExternalVideoBlock':
            return (
              <div css={styles.videoContainer}>
                <MultimediaBlockVideo
                  data={block}
                  key={i}
                  layout={layout}
                  carouselHeight={height}
                  setCarouselHeight={setHeight}
                />
              </div>
            )
        }
      })}
    </Carousel>
  )
}

export const MultimediaBlockFragment = graphql`
  fragment MultimediaBlock on DatoCmsMultimediaBlock {
    __typename
    id: originalId
    media {
      ... on DatoCmsImageBlock {
        ...MultimediaBlockImage
      }
      ... on DatoCmsExternalVideoBlock {
        ...ExternalVideoBlock
      }
    }
  }
`
